
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceObject } from '@/models/jsonapi'
  import { Contract } from "@/models/contracts";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { extractRelationshipIds, localIndex, fetchRelated } from "@/utils/apiDataHelper"
  import { internalContractIndexColumns } from '@/view_scripts/table_columns/contract_columns'

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed<number>(() => root.$store.state.contracts.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const contractQueryParams = {
          'filter[archived]': false,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-created_at',
          'include': 'agency,household.lead.seller',
          'fields[agencies]': 'name',
          'fields[households]': 'designation',
          'fields[address]': 'last_name',
          'fields[sellers]': 'first_name,bill_initials',
        }
        return root.$store.dispatch('contracts/load2', contractQueryParams).then((contractResponse) => {
          const index = localIndex(contractResponse)
          return contractResponse.data.map((contract:any) => {
            const agency = fetchRelated(contract, 'agency', index)
            const hh = fetchRelated(contract, 'household', index)
            const seller = fetchRelated(contract, 'household.lead.seller', index)
            // const crAdds = fetchRelated(hh, 'care_receivers.address', index) || []
            // const familyNames = crAdds.map((add:any) => add?.attributes?.lastName||'NA').join(', ')
            contract.meta = {
              agencyName: agency?.attributes?.name || 'NA',
              hhDesignation: hh?.attributes?.designation,
              seller: `${seller.attributes.firstName} (${seller.attributes.billInitials})`
            }
            return contract
          })
        })
      }

      const columns = ref(internalContractIndexColumns)

      const titleActions = []

      return {
        getItems,
        totalItems,
        columns,
        titleActions
      }
    }
  })
